import { useProjectVendorsSelector } from 'src/project/state/hook';

const useProjectHasAutoinviteContacts = () => {
    const projectVendors = useProjectVendorsSelector();
    const contacts = projectVendors?.companies?.map((v) => v?.contact);
    const hasAutoinviteContacts = contacts.some((contact) => contact?.autoinvite);

    return hasAutoinviteContacts;
};

export default useProjectHasAutoinviteContacts;
