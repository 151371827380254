import { Suspense, lazy } from 'react';
import { Outlet, RouteObject, useLocation } from 'react-router';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import QuoteProposalReadOnlyGuard from 'src/components/rfp/QuoteProposalReadOnlyGuard';
import { currencieSelectorData } from 'src/currencies/hook';
import LoginRoutes from 'src/login/routes';
import SessionManager from 'src/login/sessionManager';
import ProjectClientSummaryPage from 'src/pages/project/projectClientSummaryPage';
import ProjectRoutes, { projectLoader } from 'src/project/routes';
import { fetchProjectData } from 'src/project/state/thunk';
import { apaduaAnnouncementsSelectorData } from 'src/redux/apaduaAnnouncements/hook';
import { companySizeEmployeesSelectorData } from 'src/redux/companySizeEmployees/hook';
import { companySizeTurnoverSelectorData } from 'src/redux/companySizeTurnover/hook';
import { countriesSelectorData } from 'src/redux/countries/hook';
import { documentTypesSelectorData } from 'src/redux/documentTypes/hook';
import { industryStandardsSelectorData } from 'src/redux/industryStandards/selectors';
import { IndustryStandardsFetcher } from 'src/redux/industryStandards/thunk';
import { selectLanguages } from 'src/redux/languages/hook';
import { salutationsSelectorData } from 'src/redux/salutations/hook';
import { serviceStandardsSelectorData } from 'src/redux/serviceStandards/selectors';
import { ServiceStandardsFetcher } from 'src/redux/serviceStandards/thunk';

import WrappedApp from './WrappedApp';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import RoleBasedGuard from './components/RoleBasedGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import { RatecardProposalProvider } from './components/ratecardproposals/RatecardProposalProvider';
import RatecardProjectRoute from './components/ratecards/RatecardProjectProviderSuspense';
import QuoteProposalRoute from './components/rfp/QuoteProposalProviderSuspense';
import QuoteRoute from './components/rfp/QuoteProviderSuspense';
import { CurrenciesFetcher } from './currencies/thunk';
import DependencyInjector from './dependencyInjection';
import ApalyticsPage from './pages/apalytics/apalyticsPage';
import Login from './pages/authentication/Login';
import { BriefPage } from './pages/brief';
import { projectBriefLoader, projectBriefsLoader } from './pages/brief/briefPage';
import { ListManagerPage } from './pages/listManager';
import { ListsFetcher } from './pages/listManager/state/thunk';
import ProjectSummaryPage from './pages/project/projectSummaryPage';
import RatecardProposalReadOnlyGuard from './pages/ratecards/ratecardProposalReadOnlyGuard';
import RatecardReadOnlyGuard from './pages/ratecards/ratecardReadOnlyGuard';
import { ProposalFetcher } from './proposal/state/thunk';
import { RatecardProjectAnnouncementsFetcher } from './ratecardProject/state/thunk';
import { ApaduaAnnouncementsFetcher } from './redux/apaduaAnnouncements/thunk';
import { CompanySizeEmployeesFetcher } from './redux/companySizeEmployees/thunk';
import { CompanySizeTurnoverFetcher } from './redux/companySizeTurnover/thunk';
import { CountriesFetcher } from './redux/countries/thunk';
import { DocumentTypesFetcher } from './redux/documentTypes/thunk';
import { LanguagesFetcher } from './redux/languages/thunk';
import { proposalDocumentTypesSelectorData } from './redux/proposalDocumentTypes/hook';
import { ProposalDocumentTypesFetcher } from './redux/proposalDocumentTypes/thunk';
import { SalutationsFetcher } from './redux/salutations/thunk';
import store from './redux/store';
import { tenantsSelectorData } from './redux/tenants/selector';
import { TenantsFetcher } from './redux/tenants/thunk';
import { fromRelayId } from './relay/utils';
import VendorCrossTenantGuard from './tenants/vendorCrossTenantGuard';

const proposalLoader = async ({ params }) => {
    const { quoteProposalId, quoteId } = params;

    const proposalPromise = store.dispatch(ProposalFetcher.action(fromRelayId(quoteProposalId)));
    const projectPromise = store.dispatch(fetchProjectData(fromRelayId(quoteId)));
    await Promise.all([proposalPromise, projectPromise]);
    return null;
};

export const ratecardProjectLoader = async ({ params }) => {
    const { id, ratecardId } = params;
    let currentId = id;

    if (ratecardId) {
        [, currentId] = atob(ratecardId).split('RatecardProjectNode:');
    }

    const ratecardProjectAnnouncementsPromise = store.dispatch(
        RatecardProjectAnnouncementsFetcher.action(currentId),
    );
    await Promise.all([ratecardProjectAnnouncementsPromise]);
    return null;
};

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Ratecards pages

const Ratecards = Loadable(lazy(() => import('./pages/ratecards/Ratecards')));
const Ratecard = Loadable(lazy(() => import('./pages/ratecards/Ratecard')));
const RatecardProposalProject = Loadable(
    lazy(() => import('./pages/ratecardproposals/RatecardProjectProposal')),
);
const RatecardProposal = Loadable(lazy(() => import('./pages/ratecardproposals/RatecardProposal')));
const RatecardProposals = Loadable(
    lazy(() => import('./pages/ratecardproposals/RatecardProposals')),
);
const RatecardProposalWrapper = Loadable(
    lazy(() => import('./pages/ratecardproposals/RatecardProposalWrapper')),
);
const RatecardProposalAnnouncements = Loadable(
    lazy(() => import('./pages/ratecardproposals/Announcements')),
);
const RatecardProposalInternalDocumentsPage = Loadable(
    lazy(() => import('./pages/ratecardproposals/InternalDocuments')),
);
const RatecardProject = Loadable(lazy(() => import('./pages/ratecards/RatecardProject')));
const TenderPlanning = Loadable(lazy(() => import('./pages/ratecards/TenderPlanningPage')));
const SupplierSelection = Loadable(lazy(() => import('./pages/ratecards/SupplierSelectionPage')));
const OtherInformation = Loadable(lazy(() => import('./pages/ratecards/OtherInformationPage')));
const RatecardProjectQuestions = Loadable(lazy(() => import('./pages/ratecards/Questions')));
const Bids = Loadable(lazy(() => import('./pages/ratecards/BidsWrapper')));
const Analytics = Loadable(lazy(() => import('./pages/ratecards/AnalyticsWrapper')));
const Overview = Loadable(lazy(() => import('./pages/ratecards/Overview')));
const Suppliers = Loadable(lazy(() => import('./pages/ratecards/Suppliers')));
const Proposals = Loadable(lazy(() => import('./pages/ratecards/Proposals')));
const SupplierDetails = Loadable(lazy(() => import('./pages/ratecards/SupplierDetailsPage')));
const Announcements = Loadable(lazy(() => import('./pages/ratecards/Announcements')));
const RatecardProjectInternalDocumentsPage = Loadable(
    lazy(() => import('./pages/ratecards/InternalDocuments')),
);
const TenderIntroduction = Loadable(
    lazy(() => import('./pages/ratecardproposals/TenderIntroduction')),
);
const Attachments = Loadable(lazy(() => import('./pages/ratecardproposals/Attachments')));
const RatecardProposalQuestions = Loadable(
    lazy(() => import('./pages/ratecardproposals/Questions')),
);
const RatecardEvaluation = Loadable(lazy(() => import('./pages/ratecards/RatecardEvaluation')));
const RatecardsTokenLogin = Loadable(lazy(() => import('./pages/ratecards/RatecardsTokenLogin')));
const RatecardUserManagment = Loadable(lazy(() => import('./pages/users/RatecardUserManagment')));
const RatecardProposalUserManagement = Loadable(
    lazy(() => import('./pages/users/RatecardProposalUserManagement')),
);
const EditMode = Loadable(lazy(() => import('./pages/ratecards/EditMode')));

// References pages

// const ProjectReferenceHolder = Loadable(lazy(() => import('./pages/references/ProjectReferenceHolder')));
const ProjectReferenceWizardPage = Loadable(
    lazy(() => import('./pages/references/ProjectReferenceWizardPage')),
);
const ProjectReferenceWizardSupplierInformation = Loadable(
    lazy(() => import('./pages/references/projectReferenceWizard/SupplierInformation')),
);
const ProjectReferenceWizardClientInformation = Loadable(
    lazy(() => import('./pages/references/projectReferenceWizard/ClientInformation')),
);
const ProjectReferenceWizardProjectInformation = Loadable(
    lazy(() => import('./pages/references/projectReferenceWizard/ProjectInformation')),
);
const ProjectReferenceWizardClientFeedback = Loadable(
    lazy(() => import('./pages/references/projectReferenceWizard/ClientFeedback')),
);

// Authentication pages
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const Logout = Loadable(lazy(() => import('./pages/authentication/Logout')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Profile = Loadable(
    lazy(() => import('./components/dashboard/account/AccountProfileSettings')),
);
const Security = Loadable(
    lazy(() => import('./components/dashboard/account/AccountSecuritySettings')),
);
const NotificationPage = Loadable(lazy(() => import('./pages/notification/NotificationPage')));

// Organization pages

const OrganizationUserManagment = Loadable(
    lazy(() => import('./pages/organization/OrganizationUserManagment')),
);
const CalendarPage = Loadable(lazy(() => import('./pages/calendar/CalendarPage')));

// RFP pages

const Quotes = Loadable(lazy(() => import('./pages/rfp/Quotes')));
const QuoteProject = Loadable(lazy(() => import('./pages/rfp/QuoteProject')));
const QuoteProposalProject = Loadable(
    lazy(() => import('./pages/rfp/proposals/QuoteProposalProject')),
);
const QuoteUserManagement = Loadable(lazy(() => import('./pages/rfp/QuoteUserManagement')));
const QuoteProposalUserManagement = Loadable(
    lazy(() => import('./pages/rfp/proposals/QuoteProposalUserManagement')),
);
const QuotesProposals = Loadable(lazy(() => import('./pages/rfp/proposals/QuoteProposals')));
const QuoteAnnouncement = Loadable(lazy(() => import('./pages/rfp/QuoteAnnouncements')));
const QuoteProposalAnnouncement = Loadable(
    lazy(() => import('./pages/rfp/proposals/QuoteProposalAnnouncements')),
);
const QuoteQuestions = Loadable(lazy(() => import('./pages/rfp/Questions')));
const QuoteProposalQuestions = Loadable(lazy(() => import('./pages/rfp/proposals/Questions')));
const QuoteInternalDocuments = Loadable(lazy(() => import('./pages/rfp/InternalDocuments')));
const QuoteProposalInternalDocuments = Loadable(
    lazy(() => import('./pages/rfp/proposals/InternalDocuments')),
);
const Vendors = Loadable(lazy(() => import('./project/vendors/vendors')));

// Explore pages

const ExplorePage = Loadable(lazy(() => import('./explore/ExplorePage')));
const CompanyProfilePage = Loadable(lazy(() => import('./explore/CompanyProfilePage')));
const CompanyAbout = Loadable(lazy(() => import('./explore/CompanyAbout')));
const CompanyExplore = Loadable(lazy(() => import('./explore/CompanyExplore')));

const RemoveHashOrLogin = () => {
    const location = useLocation();
    if (location.pathname === '/' && location.hash) {
        return <Navigate to={location.hash.replace('#', '')} replace />;
    }
    return <Navigate to="/login" replace />;
};

const checkDataExists = (selector) => {
    const state = store.getState();
    return selector(state).length > 0;
};

const dispatchIfDataNotExists = (actionCreator, selector) => {
    if (!checkDataExists(selector)) {
        return store.dispatch(actionCreator());
    }
    return Promise.resolve();
};

export const prerequisitesLoader = async () => {
    const promises = [
        dispatchIfDataNotExists(ApaduaAnnouncementsFetcher.action, apaduaAnnouncementsSelectorData),
        dispatchIfDataNotExists(CountriesFetcher.action, countriesSelectorData),
        dispatchIfDataNotExists(LanguagesFetcher.action, selectLanguages),
        dispatchIfDataNotExists(CurrenciesFetcher.action, currencieSelectorData),
        dispatchIfDataNotExists(SalutationsFetcher.action, salutationsSelectorData),
        dispatchIfDataNotExists(TenantsFetcher.action, tenantsSelectorData),
        dispatchIfDataNotExists(IndustryStandardsFetcher.action, industryStandardsSelectorData),
        dispatchIfDataNotExists(ServiceStandardsFetcher.action, serviceStandardsSelectorData),
        dispatchIfDataNotExists(DocumentTypesFetcher.action, documentTypesSelectorData),
        dispatchIfDataNotExists(
            ProposalDocumentTypesFetcher.action,
            proposalDocumentTypesSelectorData,
        ),
        dispatchIfDataNotExists(CompanySizeTurnoverFetcher.action, companySizeTurnoverSelectorData),
        dispatchIfDataNotExists(
            CompanySizeEmployeesFetcher.action,
            companySizeEmployeesSelectorData,
        ),
    ];

    return Promise.all(promises);
};

const routes: RouteObject[] = [
    { index: true, element: <RemoveHashOrLogin /> },
    {
        path: 'login',
        children: [
            ...LoginRoutes,
            {
                path: ':token',
                element: <RatecardsTokenLogin />,
            },
        ],
    },
    {
        path: 'logout',
        element: (
            <WrappedApp>
                <Logout />
            </WrappedApp>
        ),
    },
    {
        path: '/authentication',
        element: (
            <WrappedApp>
                <MainLayout />
            </WrappedApp>
        ),
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'login-unguarded',
                element: <Login />,
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />,
            },
        ],
    },
    {
        path: '/',
        loader: prerequisitesLoader,
        element: (
            <WrappedApp>
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            </WrappedApp>
        ),
        children: [
            {
                path: 'user',
                element: <DashboardLayout />,
                children: [
                    {
                        path: 'lists',
                        requiresTenant: true,
                        loader: async () => {
                            await store.dispatch(ListsFetcher.action());
                            return null;
                        },
                        element: <ListManagerPage />,
                    },
                ],
            },
            {
                path: 'dashboard',
                element: <DashboardLayout />,
                children: [
                    {
                        index: true,
                        element: (
                            <RoleBasedGuard>
                                <DashboardLayout />
                            </RoleBasedGuard>
                        ),
                    },
                    {
                        path: 'calendar',
                        element: <CalendarPage />,
                    },
                    {
                        path: 'notifications',
                        children: [
                            {
                                path: 'all',
                                element: <NotificationPage />,
                            },
                        ],
                    },
                    {
                        path: 'brief-generation',
                        loader: projectBriefsLoader,
                        element: <BriefPage />,
                        requiresTenant: true,
                        children: [
                            {
                                path: ':id',
                                loader: SessionManager.createLoader(projectBriefLoader),
                                element: <BriefPage />,
                            },
                        ],
                    },

                    {
                        path: 'account',
                        element: <Account />,
                        children: [
                            {
                                path: 'profile',
                                element: <Profile />,
                            },
                            {
                                path: 'security',
                                element: <Security />,
                            },
                        ],
                    },
                    {
                        path: 'organization/users',
                        requiresTenant: true,
                        element: <OrganizationUserManagment />,
                    },
                    {
                        path: 'ratecards',
                        children: [
                            {
                                index: true,
                                element: (
                                    <>
                                        <VendorCrossTenantGuard>
                                            <RoleBasedGuard client noNavigate>
                                                <Ratecards />
                                            </RoleBasedGuard>
                                            <RoleBasedGuard consultant noNavigate>
                                                <RatecardProposals />
                                            </RoleBasedGuard>
                                        </VendorCrossTenantGuard>
                                    </>
                                ),
                            },
                            {
                                path: ':ratecardId',
                                element: (
                                    <RatecardProjectRoute>
                                        <Outlet />
                                    </RatecardProjectRoute>
                                ),
                                requiresTenant: true,
                                children: [
                                    {
                                        path: 'setup',
                                        element: (
                                            <RatecardReadOnlyGuard>
                                                <RoleBasedGuard client>
                                                    <RatecardProject />
                                                </RoleBasedGuard>
                                            </RatecardReadOnlyGuard>
                                        ),
                                        children: [
                                            {
                                                path: 'tender-planning',
                                                element: <TenderPlanning />,
                                            },
                                            {
                                                path: 'supplier-selection',
                                                element: <SupplierSelection />,
                                            },
                                            {
                                                path: 'ratecard',
                                                element: <Ratecard />,
                                            },
                                            {
                                                path: 'other-information',
                                                element: <OtherInformation />,
                                            },
                                            {
                                                path: 'documents',
                                                element: <RatecardProjectInternalDocumentsPage />,
                                            },
                                            {
                                                path: 'announcements',
                                                element: <Announcements />,
                                            },

                                            {
                                                path: 'questions',
                                                element: <RatecardProjectQuestions />,
                                            },
                                            {
                                                path: 'users',
                                                element: <RatecardUserManagment />,
                                            },
                                            {
                                                path: '*',
                                                element: <Navigate to="tender-planning" replace />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'evaluation',
                                        element: (
                                            <RatecardReadOnlyGuard>
                                                <RoleBasedGuard client>
                                                    <RatecardEvaluation />
                                                </RoleBasedGuard>
                                            </RatecardReadOnlyGuard>
                                        ),
                                        loader: SessionManager.createLoader(ratecardProjectLoader),
                                        children: [
                                            {
                                                path: 'edit',
                                                children: [
                                                    {
                                                        path: 'info',
                                                        element: <EditMode />,
                                                    },
                                                    {
                                                        path: 'tender-planning',
                                                        element: <TenderPlanning editMode />,
                                                    },
                                                    {
                                                        path: 'supplier-selection',
                                                        element: <SupplierSelection editMode />,
                                                    },
                                                    {
                                                        path: 'ratecard',
                                                        element: <Ratecard editMode />,
                                                    },
                                                    {
                                                        path: 'other-information',
                                                        element: <OtherInformation editMode />,
                                                    },
                                                    {
                                                        path: '*',
                                                        element: <Navigate to="info" replace />,
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'overview',
                                                element: <Overview />,
                                            },
                                            {
                                                path: 'bids',
                                                element: <Bids />,
                                            },
                                            {
                                                path: 'analytics',
                                                element: <Analytics />,
                                            },
                                            {
                                                path: 'suppliers',
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <Suppliers />,
                                                    },
                                                    {
                                                        path: ':proposalId',
                                                        element: (
                                                            <RoleBasedGuard client>
                                                                <RatecardProposalProvider>
                                                                    <SupplierDetails />
                                                                </RatecardProposalProvider>
                                                            </RoleBasedGuard>
                                                        ),
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'proposals',
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <Proposals />,
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'documents',
                                                element: <RatecardProjectInternalDocumentsPage />,
                                            },
                                            {
                                                path: 'announcements',
                                                element: <Announcements />,
                                            },
                                            {
                                                path: 'questions',
                                                element: <RatecardProjectQuestions />,
                                            },
                                            {
                                                path: 'users',
                                                element: <RatecardUserManagment />,
                                            },
                                            {
                                                path: 'calendar',
                                                element: <CalendarPage />,
                                            },
                                            {
                                                path: '*',
                                                element: <Navigate to="overview" replace />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'proposals',
                                        element: (
                                            <RoleBasedGuard consultant>
                                                <RatecardProposalWrapper />
                                            </RoleBasedGuard>
                                        ),
                                        loader: SessionManager.createLoader(ratecardProjectLoader),
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <RoleBasedGuard consultant>
                                                        <RatecardProposals />
                                                    </RoleBasedGuard>
                                                ),
                                            },
                                            {
                                                path: ':proposalId',
                                                children: [
                                                    {
                                                        path: 'setup',
                                                        element: (
                                                            <RoleBasedGuard consultant>
                                                                <RatecardProposalProvider>
                                                                    <RatecardProposalReadOnlyGuard>
                                                                        <RatecardProposalProject />
                                                                    </RatecardProposalReadOnlyGuard>
                                                                </RatecardProposalProvider>
                                                            </RoleBasedGuard>
                                                        ),
                                                        children: [
                                                            {
                                                                path: 'tender-introduction',
                                                                element: <TenderIntroduction />,
                                                            },
                                                            {
                                                                path: 'ratecard',
                                                                element: <RatecardProposal />,
                                                            },
                                                            {
                                                                path: 'attachments',
                                                                element: <Attachments />,
                                                            },
                                                            {
                                                                path: 'announcements',
                                                                element: (
                                                                    <RatecardProposalAnnouncements />
                                                                ),
                                                            },
                                                            {
                                                                path: 'questions',
                                                                element: (
                                                                    <RatecardProposalQuestions />
                                                                ),
                                                            },
                                                            {
                                                                path: 'documents',
                                                                element: (
                                                                    <RatecardProposalInternalDocumentsPage />
                                                                ),
                                                            },
                                                            {
                                                                path: 'users',
                                                                element: (
                                                                    <RatecardProposalUserManagement />
                                                                ),
                                                            },
                                                            {
                                                                path: 'calendar',
                                                                element: <CalendarPage />,
                                                            },
                                                            {
                                                                path: '*',
                                                                element: (
                                                                    <Navigate
                                                                        to="tender-introduction"
                                                                        replace
                                                                    />
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        path: '*',
                                        element: <Navigate to=".." replace />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'rfp',
                        children: [
                            {
                                index: true,
                                element: (
                                    <>
                                        <VendorCrossTenantGuard>
                                            <RoleBasedGuard client noNavigate>
                                                <Quotes />
                                            </RoleBasedGuard>
                                            <RoleBasedGuard consultant noNavigate>
                                                <QuotesProposals />
                                            </RoleBasedGuard>
                                        </VendorCrossTenantGuard>
                                    </>
                                ),
                            },
                            {
                                path: ':quoteId',
                                element: (
                                    <QuoteRoute>
                                        <Outlet />
                                    </QuoteRoute>
                                ),
                                loader: SessionManager.createLoader(projectLoader),
                                requiresTenant: true,
                                children: [
                                    {
                                        path: 'setup',
                                        element: (
                                            <RoleBasedGuard client>
                                                <QuoteProject />
                                            </RoleBasedGuard>
                                        ),
                                        children: [
                                            {
                                                path: 'users',
                                                element: <QuoteUserManagement />,
                                            },
                                            {
                                                path: 'announcements',
                                                element: <QuoteAnnouncement />,
                                            },
                                            {
                                                path: 'documents',
                                                element: <QuoteInternalDocuments />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'evaluation',
                                        element: (
                                            <RoleBasedGuard client>
                                                <QuoteProject />
                                            </RoleBasedGuard>
                                        ),
                                        children: [
                                            {
                                                path: 'users',
                                                element: <QuoteUserManagement />,
                                            },
                                            {
                                                path: 'announcements',
                                                element: <QuoteAnnouncement />,
                                            },
                                            {
                                                path: 'questions',
                                                element: <QuoteQuestions />,
                                            },
                                            {
                                                path: 'vendors',
                                                element: <Vendors />,
                                            },
                                            {
                                                path: 'summary',
                                                element: <ProjectClientSummaryPage />,
                                            },
                                            {
                                                path: 'documents',
                                                element: <QuoteInternalDocuments />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'proposals',
                                        children: [
                                            {
                                                path: ':quoteProposalId',
                                                element: (
                                                    <QuoteProposalRoute>
                                                        <QuoteProposalReadOnlyGuard>
                                                            <Outlet />
                                                        </QuoteProposalReadOnlyGuard>
                                                    </QuoteProposalRoute>
                                                ),
                                                loader: SessionManager.createLoader(proposalLoader),
                                                children: [
                                                    {
                                                        path: 'setup',
                                                        element: (
                                                            <RoleBasedGuard consultant>
                                                                <QuoteProposalProject />
                                                            </RoleBasedGuard>
                                                        ),
                                                        children: [
                                                            {
                                                                path: 'users',
                                                                element: (
                                                                    <QuoteProposalUserManagement />
                                                                ),
                                                            },
                                                            {
                                                                path: 'announcements',
                                                                element: (
                                                                    <QuoteProposalAnnouncement />
                                                                ),
                                                                loader: SessionManager.createLoader(
                                                                    projectLoader,
                                                                ),
                                                            },
                                                            {
                                                                path: 'questions',
                                                                element: <QuoteProposalQuestions />,
                                                            },
                                                            {
                                                                path: 'summary',
                                                                element: <ProjectSummaryPage />,
                                                                loader: SessionManager.createLoader(
                                                                    projectLoader,
                                                                ),
                                                            },
                                                            {
                                                                path: 'documents',
                                                                element: (
                                                                    <QuoteProposalInternalDocuments />
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'explore',
                loader: async () => {
                    await store.dispatch(ListsFetcher.action());
                    return null;
                },
                element: <DashboardLayout />,
                children: [
                    {
                        index: true,
                        element: <ExplorePage />,
                    },
                    {
                        path: ':companyId',
                        element: <CompanyProfilePage />,
                        children: [
                            {
                                path: 'about',
                                element: <CompanyAbout />,
                            },
                            {
                                path: 'explore',
                                element: <CompanyExplore />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'apalytics',
                element: <DashboardLayout />,
                children: [
                    {
                        index: true,
                        element: <ApalyticsPage />,
                    },
                ],
            },
            ...ProjectRoutes,
        ],
    },
    {
        path: '/project-references',
        element: (
            <WrappedApp>
                <MainLayout />
            </WrappedApp>
        ),
        children: [
            {
                path: ':id',
                element: <ProjectReferenceWizardPage />,
                children: [
                    {
                        path: 'supplier-information',
                        element: <ProjectReferenceWizardSupplierInformation />,
                    },
                    {
                        path: 'client-information',
                        element: <ProjectReferenceWizardClientInformation />,
                    },
                    {
                        path: 'project-information',
                        element: <ProjectReferenceWizardProjectInformation />,
                    },
                    {
                        path: 'client-feedback',
                        element: <ProjectReferenceWizardClientFeedback />,
                    },
                ],
            },
        ],
    },
];

const router = createBrowserRouter(routes);
DependencyInjector.router = router;
router.getCurrentFullUrl = () => {
    const { pathname, search, hash } = router.state.navigation.location ?? router.state.location;
    const { origin } = window.location;
    const url = new URL(origin);
    url.pathname = pathname || '';
    url.search = search || '';
    url.hash = hash || '';
    return url.toString();
};
export default routes;
