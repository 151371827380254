import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Grid, Typography } from '@mui/material';

import { QuotesLegacyAPI } from 'src/apis/quotesLegacyAPI';
import ConfirmationModal from 'src/components/common/confirmationModal';
import IncompleteUserProfileModal from 'src/components/common/incompleteUserProfileModal';
import useAuth from 'src/hooks/useAuth';
import ChecklistDrawer from 'src/project/checklist';
import { ProjectFormData } from 'src/project/state/sanitizers';
import DismissibleToast from 'src/utils/DismissibleToast';

import useProjectHasAutoinviteContacts from '../useProjectHasAutoinviteContacts';

const quotesLegacyAPI = new QuotesLegacyAPI();

interface Props {
    project: ProjectFormData;
}

const SubmitRFP = ({ project }: Props) => {
    const { i18n } = useLingui();
    const [loading, setLoading] = React.useState(false);
    const [checklistOpen, setChecklistOpen] = React.useState(false);
    const [userProfileModalOpen, setUserProfileModalOpen] = React.useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();
    const hasAutoinviteContacts = useProjectHasAutoinviteContacts();

    const { emailValidated, profileCompleted } = user;

    /*
        This function is using QuotesLegacyAPI to support Submit RFP step 6, until all steps are fully migrated
        to the new UI plus the APIS. No reducers or thunks will be made for the below and these need to be replaced
        after everyhting has been migrated
    */

    const handleSubmitClick = React.useCallback(
        (handleOpenConfirmModal) => () => {
            if (!emailValidated || !profileCompleted) {
                setUserProfileModalOpen(true);
                return;
            }
            handleOpenConfirmModal();
        },
        [emailValidated, profileCompleted],
    );

    const submitRFP = async () => {
        try {
            const { id } = project;
            setLoading(true);
            const quote = await quotesLegacyAPI.getQuote(id);
            const payload = {
                ...quote,
                data: {
                    ...quote.data,
                    attributes: {
                        ...quote.data.attributes,
                        'current-step': 60,
                        'save-as-draft': false,
                    },
                },
            };
            await quotesLegacyAPI.update(payload, id);
            await quotesLegacyAPI.submit(id);
            toast.success(
                DismissibleToast(i18n._('Your project has been submitted successfully.')),
            );
            const projectRelayId = btoa(`QuoteNode:${id}`);
            navigate(`/dashboard/rfp/${projectRelayId}/evaluation/summary`);
        } catch (error) {
            toast.error(
                DismissibleToast(
                    i18n._(
                        'An error has occurred. Please make sure that all mandatory fields have been completed and try again.',
                    ),
                ),
            );
        } finally {
            setLoading(false);
        }
    };

    const triggerButtonComponent = ({ handleOpen }) => (
        <Grid display="flex" justifyContent="center">
            <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                onClick={handleSubmitClick(handleOpen)}
            >
                <Typography>
                    <Trans>Submit RFP</Trans>
                </Typography>
            </LoadingButton>
        </Grid>
    );
    const openChecklist = () => setChecklistOpen(true);
    const beforeClose = () => setChecklistOpen(false);

    const submitRfpButton = React.useMemo(
        () => (
            <ConfirmationModal
                sx={{ width: 640 }}
                triggerButtonComponent={triggerButtonComponent}
                onConfirm={submitRFP}
                description={
                    <>
                        <Trans>
                            You are about to submit your project request. After you have submitted
                            your request, you are unable to make changes. Are you sure you want to
                            submit your project request?
                        </Trans>
                        {hasAutoinviteContacts && (
                            <Alert severity="warning">
                                <Trans>
                                    Contacts that are marked for auto invitation will be invited as
                                    soon as the project is submitted, and not at the defined RFP
                                    Release Date.
                                </Trans>
                            </Alert>
                        )}
                    </>
                }
                confirmButtonText={i18n._('YES, submit RFP')}
                cancelButtonText={i18n._('NO, I want to edit the RFP')}
            />
        ),
        [triggerButtonComponent, submitRFP],
    );

    return (
        <>
            <ChecklistDrawer
                externalControl={checklistOpen}
                beforeClose={beforeClose}
                actionButton={submitRfpButton}
            />
            <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                color="primary"
                loading={loading}
                onClick={openChecklist}
            >
                <Typography>
                    <Trans>Submit RFP</Trans>
                </Typography>
            </LoadingButton>

            <IncompleteUserProfileModal
                withHeader={false}
                isOpen={userProfileModalOpen}
                close={() => setUserProfileModalOpen(false)}
            />
        </>
    );
};

export default SubmitRFP;
