import React from 'react';

import { Trans } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, TableCell, TableRow, Typography, tableRowClasses } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import useIsAutoInviteEnabled from 'src/hooks/useIsAutoIntiveEnabled';
import { useTheme } from 'src/theme';

import { usePatchProjectVendorContactAsync, useProjectVendorsStatus } from '../state/hook';
import { ProjectVendor } from '../types';
import { VendorContactInfo } from './contactInformationDrawer';

interface VendorsTableRowProps {
    row: ProjectVendor;
    onRemove: () => void;
    onEdit: () => void;
}

export const style = {
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
    overflow: 'hidden',
};

const ROW_HEIGHT = 32;
const MIN_WIDTH_NAME_CELL = '180px';

const StyledTableRow = styled(TableRow)(() => ({
    [`&.${tableRowClasses.root}`]: {
        height: `${ROW_HEIGHT}px`,
        maxHeight: `${ROW_HEIGHT}px`,
        overflow: 'auto',
    },

    '& .table-row-name-column': {
        display: 'flex',
        position: 'relative',
        height: 'inherit',
        width: 200,
    },
    '& .table-row-contact': {
        right: 0,
        top: 0,
        display: 'flex',
        gap: 4,
    },
    '& .table-row-actions': {
        right: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 4,
    },
}));

const VendorsTableRow = ({
    row,
    onRemove: handleRemove,
    onEdit: handleEdit,
}: VendorsTableRowProps) => {
    const isAutoInviteEnabled = useIsAutoInviteEnabled();
    const theme = useTheme();
    const { id, logo, companyName, website, contact } = row;
    const projectVendorsStatus = useProjectVendorsStatus();
    const isLoading = projectVendorsStatus === 'loading';
    const patchProjectVendorContact = usePatchProjectVendorContactAsync();
    const handleAutoInviteSwitch = React.useCallback(() => {
        patchProjectVendorContact({
            contactId: contact.id,
            data: {
                ...contact,
                autoinvite: !contact?.autoinvite,
            },
        });
    }, [contact?.autoinvite]);
    return (
        <StyledTableRow
            sx={() => ({
                '&:last-child td, &:last-child th': { border: 0 },
            })}
        >
            <TableCell
                width="48px"
                sx={{
                    px: 2,
                    py: 1,
                }}
            >
                <CustomCompanyIcon
                    company={{
                        companyId: id,
                        companyName,
                        logo,
                    }}
                />
            </TableCell>
            <TableCell
                height="inherit"
                sx={{
                    px: 2,
                    py: 1,
                }}
            >
                <Box className="table-row-name-column">
                    <Grid
                        container
                        flexDirection="column"
                        justifyContent="center"
                        sx={{ height: '100%' }}
                    >
                        <Typography
                            sx={{
                                ...style,
                                '-webkit-line-clamp': '2',
                                minWidth: MIN_WIDTH_NAME_CELL,
                            }}
                        >
                            {companyName}
                        </Typography>
                    </Grid>
                </Box>
            </TableCell>
            <TableCell
                height="inherit"
                sx={{
                    px: 2,
                    py: 1,
                }}
            >
                <Box>
                    <Grid
                        container
                        flexDirection="column"
                        justifyContent="center"
                        sx={{ height: '100%' }}
                    >
                        <Typography
                            sx={{
                                ...style,
                                '-webkit-line-clamp': '2',
                                minWidth: MIN_WIDTH_NAME_CELL,
                            }}
                        >
                            <a
                                style={{ color: theme.palette.primary.main }}
                                href={website}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {website}
                            </a>
                        </Typography>
                    </Grid>
                </Box>
            </TableCell>
            <TableCell
                height="inherit"
                sx={{
                    px: 2,
                    py: 1,
                }}
            >
                <Box className="table-row-contact">
                    {contact ? (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleEdit}
                            endIcon={<EditIcon />}
                        >
                            {`${contact.individual.firstName} ${contact.individual.surname}`}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEdit}
                            startIcon={<AddIcon />}
                        >
                            <Trans>Add contact info</Trans>
                        </Button>
                    )}
                </Box>
            </TableCell>

            {isAutoInviteEnabled && (
                <>
                    <TableCell
                        height="inherit"
                        sx={{
                            px: 2,
                            py: 1,
                        }}
                    >
                        <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            className="table-row-contact"
                        >
                            {contact ? <VendorContactInfo isVerified={contact.isVerified} /> : null}
                        </Box>
                    </TableCell>

                    <TableCell
                        height="inherit"
                        sx={{
                            px: 2,
                            py: 1,
                        }}
                    >
                        <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            className="table-row-contact"
                        >
                            {contact ? (
                                <Switch
                                    disabled={!contact.isVerified || isLoading}
                                    checked={contact.autoinvite}
                                    color="primary"
                                    onChange={handleAutoInviteSwitch}
                                />
                            ) : null}
                        </Box>
                    </TableCell>
                </>
            )}

            <TableCell
                height="inherit"
                sx={{
                    px: 2,
                    py: 1,
                }}
            >
                <Box className="table-row-actions">
                    <Button variant="text" onClick={handleRemove}>
                        <DeleteIcon color="error" />
                    </Button>
                </Box>
            </TableCell>
        </StyledTableRow>
    );
};

export default VendorsTableRow;
