import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { LicenseWarning } from 'src/components/utils/LicenseWarning';
import useAuth from 'src/hooks/useAuth';
import useIsBriefEnabled, { USER_BRIEF_ROLE } from 'src/hooks/useIsBriefEnabled';
import usePersistToLocalStorage from 'src/hooks/usePersistToLocalStorage';
import { usePatchProjectInfoAsync } from 'src/project/state/hook';
import ProjectBriefContainer from 'src/projectBrief/projectBriefContainer';
import { clearSuggestions } from 'src/projectBrief/state/actions';
import { useBriefData } from 'src/projectBrief/state/hooks';
import { LOCAL_STORAGE_KEY, ProjectInfoDefaultvalue } from 'src/projectBrief/state/reducer';
import { fetchAllProjectBrief, fetchProjectBrief } from 'src/projectBrief/state/thunk';
import store from 'src/redux/store';
import { useProjectBriefTour } from 'src/tours/projectBriefTour';

export const projectBriefsLoader = async ({ params }) => {
    const { id } = params;

    if (id) {
        return null;
    }

    store.dispatch(clearSuggestions());
    const projectBrief = store.dispatch(fetchAllProjectBrief());

    await Promise.all([projectBrief]);
    return null;
};

export const projectBriefLoader = async ({ params }) => {
    const { id } = params;
    store.dispatch(clearSuggestions());
    const projectBrief = store.dispatch(fetchProjectBrief({ id }));
    const projectBriefs = store.dispatch(fetchAllProjectBrief());

    await Promise.all([projectBrief, projectBriefs]);
    return null;
};

const BriefPage = () => {
    useProjectBriefTour();
    const navigate = useNavigate();
    const patchProjectInfo = usePatchProjectInfoAsync();
    const [savedValues, saveToLocalStorage] = usePersistToLocalStorage(
        LOCAL_STORAGE_KEY,
        ProjectInfoDefaultvalue,
    );
    const briefData = useBriefData();
    const params = useParams();
    const currentBrief =
        params?.id && (briefData || []).find((item) => item.id === parseInt(params.id, 10));
    const handleClear = () => {
        if (params.id) {
            const newPath = '/dashboard/brief-generation';
            navigate(newPath);
        }
    };
    const isBriefEnabled = useIsBriefEnabled();
    const handleSave = async (values) => {
        await patchProjectInfo(values);
    };

    const { user } = useAuth();

    const hasLicence = user.apps.includes(USER_BRIEF_ROLE) && isBriefEnabled;
    return hasLicence ? (
        <ProjectBriefContainer
            onClear={handleClear}
            onSave={handleSave}
            project={currentBrief || savedValues}
            onChange={saveToLocalStorage}
        />
    ) : (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                p: 3,
            }}
        >
            <LicenseWarning module="brief" />
        </Box>
    );
};

export default BriefPage;
